<template>
  <vx-card :title="title">
    <div class="vx-col w-1/1.5">
      <div class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Saf ID</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="code"
              :disabled="true"
              :readonly="true"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Warehouse Code</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="warehouse"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2" v-if="safType === 'Interwarehouse'">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>To Warehouse Code</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="towarehouse"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Document Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="countingDate"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div v-if="status === 3 && remark === 'Adjust'" class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Posting Date</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="postingDate"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>SAF Type</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="safType"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2" v-if="safType === 'Adjustment Stock'">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Cost Center</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="costCenter"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2" v-if="safType === 'Interwarehouse'">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>COA</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              v-model="chartOfAccount"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Note</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-textarea v-model="note" class="w-full bg-grey-light" :disabled="true"/>
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Delta Only</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-td>
              <vs-checkbox v-model="deltaOnly"></vs-checkbox>
            </vs-td>
          </div>
        </div>
      </div>

      <div class="vx-col w-1/2">
        <div class="vx-row mb-12">
          <div class="vx-col sm:w-1/5 w-full">
            <span>System Qty as of</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input
              class="w-full bg-grey-light"
              :value="systemQtyAsOf"
              :disabled="true"
            />
          </div>
        </div>
      </div>

      <vs-divider style="width: 50%; margin-left: 10%">
        List Attachment
      </vs-divider>

      <div
        class="vx-row mb-3 mt-6 w-4/4"
        style="width: 50%; margin-left: 10%"
      >
        <table class="vs-table vs-table--tbody-table">
          <template>
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
              v-for="tr in fileAttachment"
            >
              <td class="td vs-table--td">{{ tr.FileName }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.Path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="handleShowAttachment(tr)"
                  />
                </vx-tooltip>
              </td>

            </tr>
          </template>
        </table>
      </div>

      <!--        tables    -->
      <template>
        <div v-if="safType === 'Adjustment Stock'">
          <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
          >
            <template slot="thead">
              <vs-th>SKU</vs-th>
              <vs-th>Warehouse Area</vs-th>
              <vs-th>System QTY</vs-th>
              <vs-th>Counting Qty</vs-th>
              <vs-th>Adjustment Qty</vs-th>
              <vs-th>HU</vs-th>
              <vs-th>MAP Create</vs-th>
              <vs-th v-if="status === 3 && remark === 'Adjust'">MAP Execute</vs-th>
              <vs-th>Est. Value</vs-th>
              <vs-th>Batch External</vs-th>
              <vs-th>Expired Date</vs-th>
              <vs-th v-if="status !== 0">Date In</vs-th>
              <vs-th>Type</vs-th>
              <vs-th>Reason</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="`${tr.SkuCode} - ${tr.ItemName}`">
                  {{ `${tr.SkuCode} - ${tr.ItemName}` }}
                </vs-td>
                <vs-td :data="`${tr.WarehouseAreaCode} - ${tr.WarehouseAreaName}`">
                  {{ `${tr.WarehouseAreaCode} - ${tr.WarehouseAreaName}` }}
                </vs-td>
                <vs-td :data="tr.Qty !== null && tr.Qty !== undefined ? tr.Qty : ''">
                  {{ tr.Qty !== null && tr.Qty !== undefined ? tr.Qty : '' }}
                </vs-td>
                <vs-td :data="tr.CountingQty !== null && tr.CountingQty !== undefined ? tr.CountingQty : ''">
                  {{ tr.CountingQty !== null && tr.CountingQty !== undefined ? tr.CountingQty : '' }}
                </vs-td>
                <vs-td :data="formatAdjustmentQty(tr)">
                  {{ formatAdjustmentQty(tr) }}
                </vs-td>
                <vs-td :data="tr.HandlingUnitName">
                  {{ tr.HandlingUnitName }}
                </vs-td>
                <vs-td :data="formatMoney(tr.CurrentPrice, 7, '.', ',')">
                  {{ formatMoney(tr.CurrentPrice, 7, '.', ',') }}
                </vs-td>
                <vs-td v-if="status === 3 && remark === 'Adjust'" :data="formatMoney(tr.LastMAP, 7, '.', ',')">
                  {{ formatMoney(tr.LastMAP, 7, '.', ',') }}
                </vs-td>
                <vs-td :data="formattedValue(tr)">
                  {{ formattedValue(tr) }}
                </vs-td>
                <vs-td :data="tr.BatchExternal">
                  {{ tr.BatchExternal }}
                </vs-td>
                <vs-td :data="formatDate(tr.ED)">
                  {{ formatDate(tr.ED) }}
                </vs-td>
                <vs-td v-if="status !== 0" :data="formatDate(tr.InTime)">
                  {{ formatDate(tr.InTime) }}
                </vs-td>
                <vs-td :data="tr.Status" :class="getStatusClass(tr.Status)">
                  {{ getStatusText(tr.Status) }}
                  <i :class="getArrowClass(tr.Status)"></i>
                </vs-td>
                <vs-td :data="tr.Reason">
                  <template v-if="status === 0">
                    <vs-input v-model="tr.Reason" class="w-full bg-grey-light"></vs-input>
                  </template>
                  <template v-else>
                    {{ tr.Reason }}
                  </template>
                </vs-td>
              </vs-tr>

              <vs-tr>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total SKU Gain</b></vs-td>
                <vs-td><b>{{ countOfSkuGain }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total SKU Loss</b></vs-td>
                <vs-td><b>{{ countOfSkuLoss }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total SKU</b></vs-td>
                <vs-td><b>{{ countOfSku }}</b></vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Qty Gain</b></vs-td>
                <vs-td><b>{{ calculateTotalQtyGain }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Qty Loss</b></vs-td>
                <vs-td><b>{{ calculateTotalQtyLoss }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Qty</b></vs-td>
                <vs-td><b>{{ calculateTotalQty }}</b></vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Value Create Gain</b></vs-td>
                <vs-td><b>{{ priceFormat(calculateTotalValueGain) }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Value Create Loss</b></vs-td>
                <vs-td><b>{{ priceFormat(calculateTotalValueLoss) }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Value Create</b></vs-td>
                <vs-td><b>{{ priceFormat(calculateTotalValue) }}</b></vs-td>
              </vs-tr>
              <vs-tr v-if="status === 3 && remark === 'Adjust'">
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Value Execute Gain</b></vs-td>
                <vs-td><b>{{ priceFormat(calculateLastMapGain) }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Value Execute Loss</b></vs-td>
                <vs-td><b>{{ priceFormat(calculateLastMapLoss) }}</b></vs-td>
                <vs-td colspan="2"></vs-td>
                <vs-td><b>Total Value Execute</b></vs-td>
                <vs-td><b>{{ priceFormat(calculateLastMap) }}</b></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>

        <div v-if="safType === 'Interwarehouse'">
          <div class="mb-6 vx-row">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
              <thead class="vs-table--thead">
              <tr>
                <td colspan="9" style="text-align: right; font-weight: bold;"></td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="searchQuery" placeholder="Search" class="w-full bg-grey-light"></vs-input>
                </td>
                <td colspan="2"></td>
              </tr>
              <!-- Table Headers -->
              <tr>
                <th>SKU Code</th>
                <th>SKU Description</th>
                <th>Warehouse Area</th>
                <th>Classifications</th>
                <th>HU</th>
                <th>Adjustment Qty</th>
                <th>Qty [UOM]</th>
                <th>Est. Value</th>
                <th>Batch Internal</th>
                <th>Batch External</th>
                <th>ED</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(tr, indextr) in filteredData" :key="indextr" style="padding-bottom: 5px">
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.sku_code ? tr.sku_code : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.item_name ? tr.item_name : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.from_warehouse_area_name ? tr.from_warehouse_area_name : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.classification_name ? tr.classification_name : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.unit ? tr.unit : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.quantity ? tr.quantity : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.quantity_uom !== null && tr.quantity_uom !== undefined ? tr.quantity_uom : ''"
                            disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="priceFormat(tr.est_value) ? priceFormat(tr.est_value) : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="tr.batch ? tr.batch : ''" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="formatDate(tr.expired_date) ? formatDate(tr.expired_date) : ''"
                            disabled
                            readonly
                            class="w-full bg-grey-light">
                  </vs-input>
                </td>
              </tr>

              <tr>
                <td colspan="9" style="text-align: right; font-weight: bold;">TOTAL Qty</td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="calculateTotalQtyInterWarehouse" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td colspan="9" style="text-align: right; font-weight: bold;">TOTAL Value</td>
                <td class="td vs-table--td" style="padding: 5px">
                  <vs-input :value="priceFormat(calculateTotalValueInterWarehouse)" disabled readonly
                            class="w-full bg-grey-light"></vs-input>
                </td>
                <td colspan="2"></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <br>
      <br>
      <br>
      <div class="mb-6 vx-row">
        <div class="w-full vx-col sm:w-1/1">
          <div
            style="position: absolute; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); ">
            <vs-button class="mb-2 mr-3" color="danger" @click="handleClose">Close</vs-button>
            <vs-button class="mb-2 mr-3" color="green" v-if="status === 0 || status === '0'" @click="handleApprove(id)">
              Close and
              Approval
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment/moment";
import Date from "./component/Date.vue";

export default {
  components: {Date},
  data() {
    return {
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      status: null,
      remark: null,
      table: this.tableDefaultState(),
      code: null,
      warehouse: null,
      countingDate: null,
      postingDate: null,
      safType: null,
      note: null,
      fileAttachment: [],
      costCenter: null,
      chartOfAccount: null,
      title: "Stock Adjustment Form - View",
      originalData: [],
      searchQuery: '',
      towarehouse: null,
      systemQtyAsOf: null,
      deltaOnly: false,
      countOfSku: null,
      countOfSkuGain: null,
      countOfSkuLoss: null,
    }
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    formatMoney(val, decimal, decSep, thouSep) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(decimal).replace(thouSep, decSep);
      var valsplit = val.split(".")
      return valsplit[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+"."+valsplit[1];
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-adjustment-form/detail/" + this.id, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code === 200) {
            this.code = resp.data.saf.Code;
            this.warehouse = resp.data.warehouse.code + " - " + resp.data.warehouse.name;
            this.countingDate = moment(resp.data.saf.Date).format('YYYY-MM-DD');
            this.postingDate = moment(resp.data.saf.PostedDate).format('YYYY-MM-DD');
            this.safType = resp.data.saf.SafType;
            this.note = resp.data.saf.Note;
            this.fileAttachment = resp.data.saf_attachment;
            this.status = resp.data.saf.Status;
            this.remark = resp.data.saf.Remark;
            this.costCenter = resp.data.saf.CostCenterCode + " - " + resp.data.saf.CostCenterName;
            this.chartOfAccount = resp.data.saf.ChartOfAccountCode + " - " + resp.data.saf.ChartOfAccountName;
            if (resp.data.saf.SystemQtyAsOf) {
              this.systemQtyAsOf = moment.utc(resp.data.saf.SystemQtyAsOf).format("DD-MM-YYYY HH:mm");
            } else {
              this.generateSystemQtyAsOf();
            }
            this.table.data = resp.data.saf_line;
            this.originalData = resp.data.saf_line;
            this.countOfSku = new Set(resp.data.saf_line.map(item => item.SkuCode)).size;
            this.countOfSkuLoss = new Set(resp.data.saf_line.filter(item => item.Status === 0).map(item => item.SkuCode)).size;
            this.countOfSkuGain = new Set(resp.data.saf_line.filter(item => item.Status === 1).map(item => item.SkuCode)).size;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataIW() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-adjustment-form/detail-interwarehouse/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code === 200) {
            this.code = resp.data.saf.code;
            this.warehouse = resp.data.from_warehouse.code + " - " + resp.data.from_warehouse.name;
            this.towarehouse = resp.data.to_warehouse.code + " - " + resp.data.to_warehouse.name;
            this.countingDate = moment(resp.data.saf.Date).format('YYYY-MM-DD');
            this.postingDate = moment(resp.data.saf.PostingDate).format('YYYY-MM-DD');
            this.safType = resp.data.saf.SafType;
            this.note = resp.data.saf.note;
            this.fileAttachment = resp.data.saf_attachment;
            this.status = resp.data.saf.status;
            this.costCenter = resp.data.saf.CostCenterCode + " - " + resp.data.saf.CostCenterName;
            this.chartOfAccount = resp.data.saf.ChartOfAccountCode + " - " + resp.data.saf.ChartOfAccountName;
            if (resp.data.saf.SystemQtyAsOf) {
              this.systemQtyAsOf = moment.utc(resp.data.saf.SystemQtyAsOf).format("DD-MM-YYYY HH:mm");
            } else {
              this.generateSystemQtyAsOf();
            }
            this.table.data = resp.data.saf_line;
            this.originalData = resp.data.saf_line;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleApprove(id) {
      this.approveID = id;
      let isValid = true;
      let errorMessage = "All fields (Batch, ED, Reason) must be filled out for each item.";

      if (this.safType === 'Adjustment Stock') {
        const batchAndEdData = this.table.data.map(tr => {
          if (tr.Status === 2) {
            if (!tr.Reason) {
              isValid = false;
              errorMessage = "Reason is required";
            }
          } else {
            if (!tr.BatchExternal || !tr.ED || !tr.Reason) {
              isValid = false;
            }
          }
          return {
            batch: tr.BatchExternal,
            ed: moment(tr.ED).format("YYYY-MM-DD"),
            sal_id: tr.id,
            reason: tr.Reason,
          };
        });

        if (!isValid) {
          this.$vs.notify({
            color: "danger",
            title: "Validation Error",
            text: errorMessage,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          return;
        }

        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to approve this data",
          accept: () => this.acceptApprove(batchAndEdData, this.systemQtyAsOf),
        });
      } else if (this.safType === 'Interwarehouse') {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to approve this data",
          accept: () => this.acceptApproveInterWarehouse(this.systemQtyAsOf),
        });
      }
    },
    acceptApprove(batchAndEdData, systemQtyAsOf) {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/approve-and-close-v2/" + this.approveID, {
          data: batchAndEdData,
          systemQtyAsOf: systemQtyAsOf,
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully send to approval request",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    acceptApproveInterWarehouse(systemQtyAsOf) {
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/stock-adjustment-form/approve-and-close-interwarehouse/" + this.approveID, {
          systemQtyAsOf: systemQtyAsOf
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully send to approved request",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleShowAttachment(file) {
      window.open(file.FileUrl, "_blank");
    },
    handleBack() {
      this.$vs.loading();
      this.$router.go(-1);
      this.$vs.loading.close();
    },
    handleClose() {
      this.$vs.loading();
      if (this.$route.query.is_approval === 1) {
        this.$router.push({
          name: "saf-approval",
          query: {tab: this.$route.query.tab},
        });
      } else {
        this.$router.push({
          name: "saf",
          query: {tab: this.$route.query.tab},
        });
      }
      this.$vs.loading.close();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD');
    },
    formatAdjustmentQty(tr) {
      if (tr.Status === 0 && tr.AdjustmentQty !== null && tr.AdjustmentQty !== undefined) {
        return `-${tr.AdjustmentQty}`;
      } else if (tr.AdjustmentQty === 0) {
        return "0";
      }
      return tr.AdjustmentQty ? tr.AdjustmentQty : '';
    },
    generateSystemQtyAsOf() {
      this.systemQtyAsOf = moment().utcOffset(7).format("DD-MM-YYYY HH:mm");
    },
    getStatusText(status) {
      if (status === 1) {
        return 'Gain';
      } else if (status === 0) {
        return 'Loss';
      }
      return status;
    },
    getStatusClass(status) {
      if (status === 1) {
        return 'status-gain';
      } else if (status === 0) {
        return 'status-loss';
      }
      return '';
    },
    getArrowClass(status) {
      if (status === 1) {
        return 'fa fa-arrow-up';
      } else if (status === 0) {
        return 'fa fa-arrow-down';
      }
      return '';
    },
    aggregateQuantities(data) {
      return data.reduce((acc, item) => {
        const unit = item.HandlingUnitName;
        const qty = parseFloat(item.AdjustmentQty || 0);
        if (acc[unit]) {
          acc[unit] += qty;
        } else {
          acc[unit] = qty;
        }
        return acc;
      }, {});
    },
    formatAggregatedQuantities(aggregatedData) {
      return Object.entries(aggregatedData)
        .map(([unit, qty]) => `${qty} ${unit}`)
        .join(', ');
    }
  },
  mounted() {
    this.id = this.$route.query.id ? parseInt(this.$route.query.id) : null;
    this.safType = this.$route.query.SafType ? decodeURIComponent(this.$route.query.SafType) : null;

    if (this.safType === 'Adjustment Stock') {
      this.getData();
    } else if (this.safType === 'Interwarehouse') {
      this.getDataIW();
    }
  },
  watch: {
    '$route': function (to) {
      this.status = to.query.status ? parseInt(to.query.status) : null;
    },
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
  computed: {
    calculateTotalValue() {
      return this.table.data.reduce((acc, item) => acc + parseFloat(item.EstValue || 0), 0);
    },
    calculateTotalValueGain() {
      return this.table.data
        .filter(item => item.Status === 1)
        .reduce((acc, item) => acc + parseFloat(item.EstValue || 0), 0);
    },
    calculateTotalValueLoss() {
      return this.table.data
        .filter(item => item.Status === 0)
        .reduce((acc, item) => acc + parseFloat(item.EstValue || 0), 0);
    },
    calculateLastMap() {
      return this.table.data.reduce((acc, item) => acc + parseFloat(item.TotalLastMAP || 0), 0);
    },
    calculateLastMapGain() {
      return this.table.data
        .filter(item => item.Status === 1)
        .reduce((acc, item) => acc + parseFloat(item.TotalLastMAP || 0), 0);
    },
    calculateLastMapLoss() {
      return this.table.data
        .filter(item => item.Status === 0)
        .reduce((acc, item) => acc + parseFloat(item.TotalLastMAP || 0), 0);
    },
    calculateTotalQty() {
      const aggregatedData = this.aggregateQuantities(this.table.data);
      return this.formatAggregatedQuantities(aggregatedData);
    },
    calculateTotalQtyGain() {
      const gainData = this.table.data.filter(item => item.Status === 1);
      const aggregatedData = this.aggregateQuantities(gainData);
      return this.formatAggregatedQuantities(aggregatedData);
    },
    calculateTotalQtyLoss() {
      const lossData = this.table.data.filter(item => item.Status === 0);
      const aggregatedData = this.aggregateQuantities(lossData);
      return this.formatAggregatedQuantities(aggregatedData);
    },
    calculateTotalValueInterWarehouse() {
      return this.table.data.reduce((acc, item) => acc + parseFloat(item.est_value || 0), 0);
    },
    calculateTotalQtyInterWarehouse() {
      return this.table.data.reduce((acc, item) => acc + parseFloat(item.quantity_uom || 0), 0);
    },
    filteredData() {
      if (this.deltaOnly) {
        return this.table.data.filter(item => item.Status !== 2 && item.Status !== '2');
      }
      return this.table.data;
    },
    formattedValue() {
      return (tr) => {
        if (this.status !== 3) {
          return this.priceFormat(tr.EstValue);
        } else if (this.remark === 'Reject') {
          return this.priceFormat(tr.EstValue);
        } else {
          return this.priceFormat(tr.TotalLastMAP);
        }
      };
    },
  }
}
</script>

<style scoped>
.status-gain {
  color: green;
}
.status-loss {
  color: red;
}
</style>
